/**
*
* Script: Countdown Timer (Displayed in Top Banner)
*
* Adapted for WP from W3 Schools
* https://www.w3schools.com/howto/tryit.asp?filename=tryhow_js_countdown
*
* @since 1.0.0
* @package Ecosfest
*/

(function($) {
	$(function() {
// Set the date we're counting down to
var countDownDate = new Date("Mar 4, 2023 2:00:00").getTime();

// Update the count down every 1 second
var x = setInterval(function() {

// Get today's date and time
var now = new Date().getTime();

// Find the distance between now and the count down date
var distance = countDownDate - now;

// Time calculations for days, hours, minutes and seconds
var days = Math.floor(distance / (1000 * 60 * 60 * 24));
var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
// var seconds = Math.floor((distance % (1000 * 60)) / 1000);

// Display the result in the element with id="demo"
document.getElementById("ecosCountdown").innerHTML = days + " Days : " + hours + " Hours : "
+ minutes + " Minutes until Ecos Fest";
// document.getElementById("ecosCountdown").innerHTML = days + " Days : " + hours + " Hours : "
// + minutes + " Minutes : " + seconds + " Seconds until Ecos Fest";

// If the count down is finished, write some text
if (distance < 0) {
clearInterval(x);
document.getElementById("ecosCountdown").innerHTML = "Event is Live Now";
}
}, 1000);

});
})(jQuery);
