/**
 *
 * Script: Header Animation (Change style on scroll)
 *
 * Adapted for WP from jQuery navigation scroll animation
 * https://codepen.io/atcraigwatson/pen/bBbWxB?css-preprocessor=less
 *
 * @since 1.0.0
 * @package Ecosfest
 */

(function ($) {
	$(function () {
		var header = $('header');

		$(window).scroll(function () {
			if ($(window).scrollTop() <= 51) {
				header.removeClass('header-scrolled');
			} else {
				header.addClass('header-scrolled');
			}
		});
	});
})(jQuery);
